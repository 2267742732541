.App {
	margin: 0 auto;
	max-width: 600px;
	height: 100%;
	font-family: "Plus Jakarta Sans";
}

.round {
	position: relative;
}

.round label {
	position: absolute;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	cursor: pointer;
	height: 22px;
	width: 22px;
	left: 0;
	top: 0;
}

.round label:after {
	border: 3px solid #fff;
	border-top: none;
	border-right: none;
	content: "";
	height: 4px;
	left: 5px;
	opacity: 0;
	position: absolute;
	top: 6px;
	transform: rotate(-45deg);
	width: 9px;
}

.round input[type="checkbox"] {
	visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
	background-color: #1ea5fc;
	border-color: #1ea5fc;
}

.round input[type="checkbox"]:checked + label:after {
	opacity: 1;
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 200px;
	background-color: black;
	color: #fff;
	font-weight: 400;
	font-size: 12px;
	line-height: 19px;
	text-align: center;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: -95%;
	margin-left: -60px;
}

@media only screen and (max-width: 320px) {
	/* For mobile phones: */
	.tooltip .tooltiptext {
		width: 130px;
		left: 14%;
	}
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}
